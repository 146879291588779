html, body, div#root {
    height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-backdrop {
  width: 100%;
  height: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  background-color:rgba(255, 255, 255, 0.85) !important;
}

.modal-error {
    display: block;
    color: red;
    float: left;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    vertical-align: middle;
}

.navbar-default .navbar-text.navbar-dim {
    color: #ccc;
}

.container-fluid {
    z-index: 0;
}

#conPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    background: white;
}

.const-form {
    font-size: 120%;
}

.const-botnav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background: #eee;
    padding-bottom: 0;
}
.const-botnav .row > div {
    margin: 0;
}

.const-midbtn-row {
    position: absolute;
    top: 90px;
    right: 0;
    left: 0;
    padding: 0 15px;
}

.const-form .const-label {
    text-align: right;
    font-weight: bold;
}
.const-form .const-header {
    text-align: center;
    font-weight: bold;
    color: #999;
    background: #eee;
    margin: 0.7em 0;

    border-top: 1px solid #ddd
}
.const-form .const-block {
    padding: 0.7em 0;
}
.const-form .const-block .row:first-child > .const-header {
    margin-top: -0.7em;
}

.const-form .row.space-below {
    margin-bottom: 0.7em;
}

.const-form .btn-group {
    display: block;
}
.const-form .const-btn {
    width: 100%;
}

.const-form .const-edit-btn {
    position: absolute;
    right: 5px;
    cursor: pointer;
    font-size: 125%;
    z-index: 10;
    background: transparent;
    border: none;
    box-shadow: none;
}
.const-form .const-org-logo {
  height: 75px;
  background-size: auto 100%;
  background-position: center;
}

.input-group {
    width: 100%;
}

#validError {
    color: red;
    text-align: center;
}

.inline {
    display: inline-block;
    margin-left: 2em;
}

#monthly-gift {
    margin-left: 20px;
    width: 90%;
}

.con-btn-link {
    background: transparent;
    border: none;
    color: #0000EE;
    padding: 0;
}

#one-time {
    width: 90%;
}

#right-button {
    float: right;
}

#block-toggle {
    display: block;
    padding-bottom: 20px;
}

.map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 50px;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  position: absolute !important;
}

.map-panel-overlay {
    position: absolute;
    top: 25px;
    left: 15%;
    right: 15%;
    z-index: 1010;
    background: rgba(255, 128, 128, 0.8);
    padding: 8px 12px;
    font-weight: bold;
}

/***********************/
/* Bootstrap overrides */
/***********************/

/* Thinner border on all list table headers */
.table>thead>tr>th {
    border-bottom: 1px solid #ddd;
}

/* Spacing on campaignsList buttons */
.btn-sm:first-child {
    margin-left: 0;
}
.btn-sm {
    margin: 0 3px;
}

/* Ensure navbar drop shadow is visible on map page */
.navbar {
    z-index: 1040;
}

.basic-multi-select {
    position: absolute;
    z-index: 1000;
    width: 35%;
    float: right;
    margin: 20px;
}

.multi-btn {
    position: relative;
    float: right;
    z-index: 1000;
    margin: 20px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-lead {
  /*
    Color for pin is #D491EB but appears to light on modal, 
    Therefore went for a slightly darker purple from a similar
    color wheel and a a even slighlty darker purple when hovering 
    to match other buttons. Used consistent styling with other button variants. 
  */
  color: white;
  background-image: linear-gradient(to bottom,#b96cf8 0,#8a4ff8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffb96cf8', endColorstr='#ff8a4ff8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #9661f7;
}

.btn-lead:hover {
  color: white;
  background-image: linear-gradient(to bottom,#8a4ff8 0,#8a4ff8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8a4ff8', endColorstr='#ff8a4ff8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #9661f7;
}

.spaced-menu {
  margin-top: 15px;
  margin-bottom: 15px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

