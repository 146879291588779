.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-lead {
  /*
    Color for pin is #D491EB but appears to light on modal, 
    Therefore went for a slightly darker purple from a similar
    color wheel and a a even slighlty darker purple when hovering 
    to match other buttons. Used consistent styling with other button variants. 
  */
  color: white;
  background-image: -webkit-linear-gradient(top,#b96cf8 0,#8a4ff8 100%);
  background-image: -o-linear-gradient(top,#b96cf8 0,#8a4ff8 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,from(#b96cf8),to(#8a4ff8));
  background-image: linear-gradient(to bottom,#b96cf8 0,#8a4ff8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffb96cf8', endColorstr='#ff8a4ff8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #9661f7;
}

.btn-lead:hover {
  color: white;
  background-image: -webkit-linear-gradient(top,#8a4ff8 0,#8a4ff8 100%);
  background-image: -o-linear-gradient(top,#8a4ff8 0,#8a4ff8 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,from(#8a4ff8),to(#8a4ff8));
  background-image: linear-gradient(to bottom,#8a4ff8 0,#8a4ff8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8a4ff8', endColorstr='#ff8a4ff8', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #9661f7;
}

.spaced-menu {
  margin-top: 15px;
  margin-bottom: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
